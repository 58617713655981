// Gatsby supports TypeScript natively!
import React, {useState}  from 'react';
import { Link } from 'gatsby';
import { Router } from '@reach/router';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import ScanResult from '../components/ScanResult';
import Default from '../components/Default';
import DeveloperPage from '../components/DeveloperPage';
 
import './index.css';
import Footer from '../components/Footer';

const Developer = () => (
  <Layout>
    <SEO title="Deepware Developer" />
    <Router basepath="/developer">
      <DeveloperPage path="/"/>
      <Default path="/" />
    </Router>
  </Layout>
);
export default Developer;
