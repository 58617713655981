import React, {useEffect, useState} from 'react';
import { Link } from "react-scroll";
import './Developer.css';
import 'swagger-ui/dist/swagger-ui.css';
import Footer from './Footer';

const DeveloperPage = () => {
  const [copiedContent, setCopiedContent] = useState(null);

  useEffect(() => {
    var SwaggerUIBundle = require('swagger-ui-dist').SwaggerUIBundle
    const ui = SwaggerUIBundle({
      url: "/swagger.json",
      dom_id: '#swagger-ui',
      presets: [
        SwaggerUIBundle.presets.apis,
        SwaggerUIBundle.SwaggerUIStandalonePreset
      ],
      docExpansion: 'list'
    })
  }, []);

  const copy = (index) => {
    navigator.clipboard.writeText(document.getElementsByClassName('tab-pane')[index].textContent)
    setCopiedContent(index)
    setTimeout(() => {
      setCopiedContent(null)
    }, 1500);
  }

  const copied = (index) => {
    return copiedContent === index;
  }

  return (
    <>
      <section className="">
        <div className="container dev-container">
          <div className="bg-light border-right col-md-2 hidden-xs" id="sidebar-wrapper">
            <div className="list-group list-group-flush">
              <Link to="overview" containerId="devContent" smooth={true} className="list-group-item list-group-item-action bg-light">Overview</Link>
              <Link to="howitworks" containerId="devContent" smooth={true} className="list-group-item list-group-item-action bg-light">How it Works?</Link>
              <Link to="baseurl" containerId="devContent" smooth={true} className="list-group-item list-group-item-action bg-light">Base URL</Link>
              <Link to="authentication" containerId="devContent" smooth={true} className="list-group-item list-group-item-action bg-light">Authentication</Link>
              <Link to="ratelimiting" containerId="devContent" smooth={true} className="list-group-item list-group-item-action bg-light">Rate Limiting</Link>
              <Link to="api" containerId="devContent" smooth={true} className="list-group-item list-group-item-action bg-light">API References</Link>
              <Link to="code" containerId="devContent" smooth={true} className="list-group-item list-group-item-action bg-light">Code Samples</Link>
            </div>
          </div>
          <div className="col-md-10 col-xs-12 dev-content" id="devContent">
            <h1>Deepware Developer</h1>
            <p>
              Deepware Developer page is explaining how to use Deepware API and SDK to scan deepfake videos.
            </p>
            <div id="overview">
              <h2>Overview</h2>
              <p>
                Deepware API is designed as RESTful.
              </p>
              <p>
                You can quickly access API endpoints to scan videos and YouTube URLs.
              </p>
            </div>  
            <div id="howitworks">
              <h2>How it Works?</h2>
              <p>
                When you send a "scan" request to Deepware API, your scan request will be added to a queue for processing. After that the video you uploaded will be scanned with Deepware AI model.  
              </p>
              <p>
                The whole process is working asynchronously. After your "scan" request, you need to handle "report-id" that you received in the response and you need to send it to "report" endpoint to get result. If "report" endpoint returns "complete" key as "true", it means your process has been completed, but it does not, you need to send another request to "report" endpoint with same "report-id" until you get "complete" as "true". 
              </p>
            </div> 
            <div id="baseurl">
              <h2>Base URL</h2>
              <p>
                <a href="https://api.deepware.ai">https://api.deepware.ai</a>
              </p>
            </div>
            <div id="authentication">
              <h2>Authentication</h2>
              <p>
                Every endpoint is required a "X-Deepware-Authentication" token in HTTP headers for authentication. You must submit to get a token before using all the services.
              </p>
              <p>
                To submit for a key please click the link below.
              </p>
              <p>
                <a href="https://deepware.ai/contact" target="_blank">Get Access Key</a>
              </p>
            </div>
            <div id="ratelimiting">
              <h2>Rate Limiting</h2>
              <p>
                Your test API key can perform limited "scan" requests. You need to contact us to extend your keys limitation. 
              </p>
            </div>
            <div id="api">
              <h2>API References</h2>
              <div id="swagger-ui"></div>
            </div>
            <div id="code">
              <h2>Code Samples</h2>
              <ul className="nav nav-tabs" role="tablist">
                <li className="nav-item active">
                  <a className="nav-link active" id="curl-tab" data-toggle="tab" href="#curl" role="tab" aria-controls="curl"
                    aria-selected="true">Curl</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" id="java-tab" data-toggle="tab" href="#java" role="tab" aria-controls="java"
                    aria-selected="true">Java</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" id="android-tab" data-toggle="tab" href="#android" role="tab" aria-controls="android"
                    aria-selected="true">Android</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" id="objc-tab" data-toggle="tab" href="#objc" role="tab" aria-controls="objc"
                    aria-selected="true">Obj-C</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" id="javascript-tab" data-toggle="tab" href="#javascript" role="tab" aria-controls="javascript"
                    aria-selected="true">JavaScript</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" id="csharp-tab" data-toggle="tab" href="#csharp" role="tab" aria-controls="csharp"
                    aria-selected="true">C#</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" id="php-tab" data-toggle="tab" href="#php" role="tab" aria-controls="php"
                    aria-selected="true">PHP</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" id="perl-tab" data-toggle="tab" href="#perl" role="tab" aria-controls="perl"
                    aria-selected="true">Perl</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" id="python-tab" data-toggle="tab" href="#python" role="tab" aria-controls="python"
                    aria-selected="true">Python</a>
                </li>
              </ul>
              <div className="tab-content">
                <div className="tab-pane fade active in" id="curl" role="tabpanel">
                  {copied(0) ?
                    <span className="copy-text">Copied</span>
                  :
                    <a className="copy-btn" title="Copy" onClick={() => copy(0)}><span className="glyphicon glyphicon-copy"></span></a>
                  }
                  <pre>{`
  curl -X POST\
  -H "X-Deepware-Authentication: [[apiKey]]"\\
  -H "Accept: application/json"\\
  -H "Content-Type: multipart/form-data"\\
  "https://api.deepware.ai/api/v1/url/scan"
                  `}</pre>
                </div>
                <div className="tab-pane fade" id="java" role="tabpanel">
                  {copied(1) ?
                    <span className="copy-text">Copied</span>
                  :
                    <a className="copy-btn" title="Copy" onClick={() => copy(1)}><span className="glyphicon glyphicon-copy"></span></a>
                  }
                  <pre>{`
  import io.swagger.client.*;
  import io.swagger.client.auth.*;
  import io.swagger.client.model.*;
  import io.swagger.client.api.ScannerApi;

  import java.io.File;
  import java.util.*;

  public class ScannerApiExample {

      public static void main(String[] args) {
          ApiClient defaultClient = Configuration.getDefaultApiClient();

          // Configure API key authorization: api_key
          ApiKeyAuth api_key = (ApiKeyAuth) defaultClient.getAuthentication("api_key");
          api_key.setApiKey("YOUR API KEY");
          // Uncomment the following line to set a prefix for the API key, e.g. "Token" (defaults to null)
          //api_key.setApiKeyPrefix("Token");

          ScannerApi apiInstance = new ScannerApi();
          String video_URL = video_URL_example; // String | 
          try {
              YtScan result = apiInstance.urlscan(video_URL);
              System.out.println(result);
          } catch (ApiException e) {
              System.err.println("Exception when calling ScannerApi#urlscan");
              e.printStackTrace();
          }
      }
  }
                `}</pre>
                </div>
                <div className="tab-pane fade" id="android" role="tabpanel">
                  {copied(2) ?
                    <span className="copy-text">Copied</span>
                  :
                    <a className="copy-btn" title="Copy" onClick={() => copy(2)}><span className="glyphicon glyphicon-copy"></span></a>
                  }
                  <pre>{`
  import io.swagger.client.api.ScannerApi;

  public class ScannerApiExample {

      public static void main(String[] args) {
          ScannerApi apiInstance = new ScannerApi();
          String video_URL = video_URL_example; // String | 
          try {
              YtScan result = apiInstance.urlscan(video_URL);
              System.out.println(result);
          } catch (ApiException e) {
              System.err.println("Exception when calling ScannerApi#urlscan");
              e.printStackTrace();
          }
      }
  }
                `}</pre>
                </div>
                <div className="tab-pane fade" id="objc" role="tabpanel">
                  {copied(3) ?
                    <span className="copy-text">Copied</span>
                  :
                    <a className="copy-btn" title="Copy" onClick={() => copy(3)}><span className="glyphicon glyphicon-copy"></span></a>
                  }
                  <pre>{`
  Configuration *apiConfig = [Configuration sharedConfig];
  // Configure API key authorization: (authentication scheme: api_key)
  [apiConfig setApiKey:@"YOUR_API_KEY" forApiKeyIdentifier:@"X-Deepware-Authentication"];
  // Uncomment below to setup prefix (e.g. Bearer) for API key, if needed
  //[apiConfig setApiKeyPrefix:@"Bearer" forApiKeyIdentifier:@"X-Deepware-Authentication"];
  String *video_URL = video_URL_example; //  (optional)

  ScannerApi *apiInstance = [[ScannerApi alloc] init];

  // Start a scan process by Youtube URL
  [apiInstance urlscanWith:video_URL
                completionHandler: ^(YtScan output, NSError* error) {
                              if (output) {
                                  NSLog(@"%@", output);
                              }
                              if (error) {
                                  NSLog(@"Error: %@", error);
                              }
                          }];
                `}</pre>
                </div>
                <div className="tab-pane fade" id="javascript" role="tabpanel">
                  {copied(4) ?
                    <span className="copy-text">Copied</span>
                  :
                    <a className="copy-btn" title="Copy" onClick={() => copy(4)}><span className="glyphicon glyphicon-copy"></span></a>
                  }
                  <pre>{`
  var DeepwareVideoScannerApi = require('deepware_video_scanner_api');
  var defaultClient = DeepwareVideoScannerApi.ApiClient.instance;

  // Configure API key authorization: api_key
  var api_key = defaultClient.authentications['api_key'];
  api_key.apiKey = "YOUR API KEY"
  // Uncomment the following line to set a prefix for the API key, e.g. "Token" (defaults to null)
  //api_key.apiKeyPrefix['X-Deepware-Authentication'] = "Token"

  var api = new DeepwareVideoScannerApi.ScannerApi()
  var opts = { 
    'video_URL': video_URL_example // {{String}} 
  };
  var callback = function(error, data, response) {
    if (error) {
      console.error(error);
    } else {
      console.log('API called successfully. Returned data: ' + data);
    }
  };
  api.urlscan(opts, callback);
                `}</pre>
                </div>
                <div className="tab-pane fade" id="csharp" role="tabpanel">
                  {copied(5) ?
                    <span className="copy-text">Copied</span>
                  :
                    <a className="copy-btn" title="Copy" onClick={() => copy(5)}><span className="glyphicon glyphicon-copy"></span></a>
                  }
                  <pre>{`
  using System;
  using System.Diagnostics;
  using IO.Swagger.Api;
  using IO.Swagger.Client;
  using IO.Swagger.Model;

  namespace Example
  {
      public class urlscanExample
      {
          public void main()
          {

              // Configure API key authorization: api_key
              Configuration.Default.ApiKey.Add("X-Deepware-Authentication", "YOUR_API_KEY");
              // Uncomment below to setup prefix (e.g. Bearer) for API key, if needed
              // Configuration.Default.ApiKeyPrefix.Add("X-Deepware-Authentication", "Bearer");

              var apiInstance = new ScannerApi();
              var video_URL = video_URL_example;  // String |  (optional) 

              try
              {
                  // Start a scan process by Youtube URL
                  YtScan result = apiInstance.urlscan(video_URL);
                  Debug.WriteLine(result);
              }
              catch (Exception e)
              {
                  Debug.Print("Exception when calling ScannerApi.urlscan: " + e.Message );
              }
          }
      }
  }
                `}</pre>
                </div>
                <div className="tab-pane fade" id="php" role="tabpanel">
                  {copied(6) ?
                    <span className="copy-text">Copied</span>
                  :
                    <a className="copy-btn" title="Copy" onClick={() => copy(6)}><span className="glyphicon glyphicon-copy"></span></a>
                  }
                  <pre>{`
  <?php
  require_once(__DIR__ . '/vendor/autoload.php');

  // Configure API key authorization: api_key
  Swagger\Client\Configuration::getDefaultConfiguration()->setApiKey('X-Deepware-Authentication', 'YOUR_API_KEY');
  // Uncomment below to setup prefix (e.g. Bearer) for API key, if needed
  // Swagger\Client\Configuration::getDefaultConfiguration()->setApiKeyPrefix('X-Deepware-Authentication', 'Bearer');

  $api_instance = new Swagger\Client\ApiScannerApi();
  $video_URL = video_URL_example; // String | 

  try {
      $result = $api_instance->urlscan($video_URL);
      print_r($result);
  } catch (Exception $e) {
      echo 'Exception when calling ScannerApi->urlscan: ', $e->getMessage(), PHP_EOL;
  }
  ?>
                `}</pre>
                </div>
                <div className="tab-pane fade" id="perl" role="tabpanel">
                  {copied(7) ?
                    <span className="copy-text">Copied</span>
                  :
                    <a className="copy-btn" title="Copy" onClick={() => copy(7)}><span className="glyphicon glyphicon-copy"></span></a>
                  }
                  <pre>{`
  use Data::Dumper;
  use WWW::SwaggerClient::Configuration;
  use WWW::SwaggerClient::ScannerApi;

  # Configure API key authorization: api_key
  $WWW::SwaggerClient::Configuration::api_key->{'X-Deepware-Authentication'} = 'YOUR_API_KEY';
  # uncomment below to setup prefix (e.g. Bearer) for API key, if needed
  #$WWW::SwaggerClient::Configuration::api_key_prefix->{'X-Deepware-Authentication'} = "Bearer";

  my $api_instance = WWW::SwaggerClient::ScannerApi->new();
  my $video_URL = video_URL_example; # String | 

  eval { 
      my $result = $api_instance->urlscan(video_URL => $video_URL);
      print Dumper($result);
  };
  if ($@) {
      warn "Exception when calling ScannerApi->urlscan: $@\n";
  }
                `}</pre>
                </div>
                <div className="tab-pane fade" id="python" role="tabpanel">
                  {copied(8) ?
                    <span className="copy-text">Copied</span>
                  :
                    <a className="copy-btn" title="Copy" onClick={() => copy(8)}><span className="glyphicon glyphicon-copy"></span></a>
                  }
                  <pre>{`
  from __future__ import print_statement
  import time
  import swagger_client
  from swagger_client.rest import ApiException
  from pprint import pprint

  # Configure API key authorization: api_key
  swagger_client.configuration.api_key['X-Deepware-Authentication'] = 'YOUR_API_KEY'
  # Uncomment below to setup prefix (e.g. Bearer) for API key, if needed
  # swagger_client.configuration.api_key_prefix['X-Deepware-Authentication'] = 'Bearer'

  # create an instance of the API class
  api_instance = swagger_client.ScannerApi()
  video_URL = video_URL_example # String |  (optional)

  try: 
      # Start a scan process by Youtube URL
      api_response = api_instance.urlscan(video_URL=video_URL)
      pprint(api_response)
  except ApiException as e:
      print("Exception when calling ScannerApi->urlscan: %s" % e)
                `}</pre>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer fixed={false} />
    </>
  );
};

export default DeveloperPage;
